<script>
import CustomerFieldOption from '@/components/enrollment/fields/customer/auto-save/CustomerFieldOption'

export default {
	name: 'CustomerFieldRadio',
	extends: CustomerFieldOption,
	computed: {
		isHeader: function () {
			return this.options.length < 1
		}
	}
}
</script>
<template>
	<form class="customer-field-radio" @submit.capture.prevent="" v-if="customerFieldType">
		<div class="customer-field-radio__label">
			<base-label v-if="showRequiredLabel"
						modifiers="required height-25 margin-r"
						:value="$t('enrollment.required')" />
			<base-label v-else-if="showOptionalLabel && !isHeader"
						modifiers="optional height-25 margin-r"
						:value="$t('enrollment.optional')" />
			<slot name="label"></slot>
		</div>
		<div class="customer-field-radio__wrapper">
			<div class="customer-field-radio__group">
				<div v-for="option in options" :key="option.key" class="customer-field-radio__item">
					<input type="radio"
						:name="option.key"
						:id="option.key"
						v-model="valueKey"
						:disabled="readOnly"
						:value="option.key"
						@change="updated"/>
					<label class="customer-field-radio--label" :for="option.key" >
						{{ option.label }}
					</label>
				</div>
			</div>
			<div class="customer-field-radio__error" v-show="!valid && edited">{{ patternError }}</div>
		</div>
	</form>
</template>
<style lang="scss">
.customer-field-radio{
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	width: 100%;
	&__group {
		display: flex;
		align-items: flex-start;
		flex-flow: column wrap;
		justify-content: space-around;
	}
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0;
		width: 100%;
	}
	&__item {
		margin: 0.25rem 0;
		& > * {
			cursor: pointer;
		}
	}
	&__error {
		color: red;
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-top: 0.25rem;
	}
}
</style>
