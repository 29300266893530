<script>
import CustomerFieldText from '@/components/enrollment/fields/customer/auto-save/CustomerFieldText'
import occtoFieldMixin from '@/mixins/occto-field-mixin'

export default {
	name: 'CustomerFieldOccto',
	extends: CustomerFieldText,
	mixins: [ occtoFieldMixin ],
	created: function () {
		this.unmask = true
	},
	watch: {
		isHidden: function (newVal) {
			if (newVal) this.$emit('loaded', false)
		},
		value: async function () {
			if (this.existingCustomerDetection) {
				await this.customerFieldService.setValid(false)
			}
		}
	},
	methods: {
		_updateValid: async function () {
			if (this.value === null || !this.existingCustomerDetection) return
			const valid = !this.facilityIsExistingCustomer && this.facility != null
			await this.customerFieldService.setValid(valid)
		}
	}
}
</script>
