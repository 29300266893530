<script>
import { mapGetters } from 'vuex'
import Card from '@/components/enrollment/cards/Card'
import Preconditions from '@/components/enrollment/Preconditions'
import BrowserAlert from '@/components/shared/BrowserAlert'
import CardStore from '@/models/Card'
import EnrollmentService from '@/services/EnrollmentService'
import StoreService from '@/services/StoreService'
import reloadPageMixin from '@/mixins/reload-page-mixin.js'

export default {
	name: 'Enrollment',
	components: { BrowserAlert, Card, Preconditions },
	mixins: [reloadPageMixin],
	computed: {
		...mapGetters({ preconditionsCheckpoint: 'cms/getPreconditionsCheckpoint' }),
		validEnrollment: function () {
			return this.enrollmentService && this.enrollmentService.isValid()
		},
		isEnrollmentComplete: function () {
			return this.enrollmentService && this.enrollmentService.isComplete()
		},
		showPreconditions: function () {
			return !(this.loading || this.reloadCheckpoint || this.resetCheckpoint)
		},
		showEnrollmentForm: function () {
			return this.cards && this.preconditionsCheckpoint && !(this.loading || this.reloadCheckpoint)
		},
		cards: function () {
			return CardStore.query().orderBy('order').get()
		},
		submitDisabled: function () {
			return !this.validEnrollment || this.isEnrollmentComplete
		}
	},
	data: () => ({
		layout: 'public-layout',
		loading: true
	}),
	reactiveProvide: {
		name: 'injectedServices',
		include: ['enrollmentService']
	},
	provide: {
		'injectedGroupServices': { default: null }
	},
	activated: async function () {
		this.loading = true
		if (!this.introCheckpoint) {
			await this.$router.push({ name: 'intro' })
		} else {
			try {
				// Resetting checkpoints to reflect enrollment rollback
				this.$store.commit('cms/setConfirmationCheckpoint', false)
				if (this.cards.length === 0) await StoreService.populate()
				this.enrollmentService = await EnrollmentService.get()
				this.loading = false
			} catch (e) {
				console.error('Error while loading: ', e)
				await this.$router.push({ name: 'error' })
			}
		}
	},
	methods: {
		goToConfirmation: function () {
			this.$router.push({ name: 'confirmation', hash: '#confirmation-section' })
		}
	}
}
</script>
<template>
	<component :is="layout" class="enrollment">
		<base-loading class="enrollment__loading" :loading="loading" modifiers="medium-spinner light-opacity">
			<browser-alert class="enrollment__alert" v-show="!loading" />
			<preconditions v-if="showPreconditions" />
			<div v-scroll v-if="showEnrollmentForm" class="enrollment__form" key="enrollmentForm">
				<card v-for="card in cards"
					:key="card.id"
					:card="card"
					:id="'card-'+card.id" />
				<div class="enrollment__submit">
					<base-button slot="content"
								:disabled="submitDisabled"
								modifiers="accent large full margin-y"
								v-html="$t('enrollment.submit')"
								@click="goToConfirmation" />
				</div>
			</div>
		</base-loading>
	</component>
</template>
<style lang="scss" scoped>
.enrollment {
	::v-deep .public-layout__container {
		min-height: 70vh;
	}
	&__alert {
		border-radius: 5px;
		border: 1px solid var(--background-variant-light);
		margin-bottom: 1rem;
	}
	&__loading {
		min-height: 70vh;
	}
	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	&__submit {
		width: 100%;
		padding: 1rem;
	}
}
</style>
