var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-field-duplicated"},[_vm._l((_vm.inputs),function(_input,index){return _c('div',{key:index,staticClass:"customer-field-duplicated__input"},[_c('div',{staticClass:"customer-field-duplicated__label"},[(_vm.showRequiredLabel)?_c('base-label',{attrs:{"modifiers":"required height-25 margin-r","value":_vm.$t('enrollment.required')}}):(_vm.showOptionalLabel)?_c('base-label',{attrs:{"modifiers":"optional height-25 margin-r","value":_vm.$t('enrollment.optional')}}):_vm._e(),_c('div',{staticClass:"customer-field-duplicated__label--original"},[_vm._t("label"),(index === 1)?_c('span',{staticClass:"customer-field-duplicated__label--repeat"},[_vm._v(" "+_vm._s(_vm.$t('enrollment.repeat'))+" ")]):_vm._e()],2)],1),_c((_vm.duplicatedFieldName + "Simple"),_vm._g(_vm._b({tag:"component",attrs:{"customer-field-type":_vm.customerFieldType,"invalid":_vm.isInvalid,"is-duplicate-field":index > 0,"enable-duplicate-field-validation":_vm.enableDuplicateFieldValidation},on:{"valueChanged":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.valueChanged.apply(void 0, [ index ].concat( argsArray ))},"validityChanged":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.validityChanged.apply(void 0, [ index ].concat( argsArray ))},"editChanged":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.editChanged.apply(void 0, [ index ].concat( argsArray ))}}},'component',_vm.$attrs,false),_vm.$listeners))],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDuplicateErrorMessage),expression:"showDuplicateErrorMessage"}],staticClass:"customer-field-duplicated__error"},[_vm._v(_vm._s(_vm.duplicateErrorMessage))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }