<script>
import BaseRadio from 'lowfoot-components/BaseRadio'
import BaseInput from 'lowfoot-components/BaseInput'
import ServiceProviderService from '@/services/ServiceProviderService'
import BaseLabel from 'lowfoot-components/BaseLabel'
import DocumentService from '@/services/DocumentService'
import ErrorService from '@/services/ErrorService'
import ErrorMessages from '@/components/error/ErrorMessages'
import ServiceTypeService from '@/services/ServiceTypeService'

export default {
	name: 'ServiceProvider',
	components: { ErrorMessages, BaseRadio, BaseInput, BaseLabel },
	inject: ['injectedServices'],
	props: {
		contentDocumentScan: {
			type: Object,
			default: null
		}
	},
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		documentService: function () {
			return new DocumentService(this.enrollmentService, this.contentDocumentScan)
		},
		selectedRegionId: function () {
			return this.enrollmentService.getRegionId()
		},
		serviceTypeId: function () {
			return this.contentDocumentScan.serviceTypeId
		},
		serviceProviderTitle: function () {
			const serviceType = ServiceTypeService.findById(this.serviceTypeId)
			return serviceType ? this.$t(`serviceProvider.select.${serviceType.code.toLowerCase()}`) : ''
		},
		contentDocumentScanScope: function () {
			return this.contentDocumentScan.scope
		},
		serviceProviders: function () {
			return ServiceProviderService.getByServiceRegion(this.serviceTypeId, this.selectedRegionId)
		},
		serviceProvidersFiltered: function () {
			if (!this.serviceProviderBrand) return this.serviceProviders
			return this.serviceProviders.filter(serviceProvider => serviceProvider.id !== this.serviceProviderBrand.id)
		},
		serviceProviderBrand: function () {
			const brand = this.$store.getters['session/getBrand']
			return ServiceProviderService.find(brand.serviceProviderId)
		},
		serviceProviderIncumbent: function () {
			return ServiceProviderService.getIncumbent(this.serviceTypeId, this.selectedRegionId)
		},
		serviceProviderAvailable: function () {
			return this.serviceProviderId !== null || this.serviceProviderName !== ''
		},
		showAllProviders: function () {
			return this.contentDocumentScanScope === 'ALL'
		},
		showErrorMessages: function () {
			return this.errorService.hasErrors()
		}
	},
	data: (vm) => ({
		isCustomProvider: false,
		serviceProviderId: null,
		serviceProviderName: '',
		show: false,
		injectedServices: vm.injectedServices,
		errorService: null
	}),
	created: function () {
		this.errorService = new ErrorService()
	},
	mounted: function () {
		this.selectProvider()
	},
	methods: {
		selectProvider: function () {
			switch (this.contentDocumentScanScope) {
				case 'ALL':
					this.show = true
					break
				case 'BRAND':
					this.autoSelectServiceProviderBrand()
					break
				case 'INCUMBENT':
					this.autoSelectServiceProviderIncumbent()
					break
				default:
					this.autoSelectServiceProviderList()
			}
		},
		clearSelection: function () {
			this.setProviderId(null, true)
			this.setProviderName('', true)
		},
		autoSelectServiceProviderBrand: function () {
			if (this.serviceProviderBrand) {
				this.autoSelectProvider(this.serviceProviderBrand.id, this.serviceProviderBrand.name)
			} else {
				this.errorService.report({
					message: 'Error auto-selecting Provider. Brand scoped for a brand without provider',
					tags: ['brand', 'provider', 'scope']
				})
			}
		},
		autoSelectServiceProviderIncumbent: function () {
			if (this.serviceProviderIncumbent) {
				this.autoSelectProvider(this.serviceProviderIncumbent.id, this.serviceProviderIncumbent.name)
			} else {
				this.errorService.report({
					message: 'Error auto-selecting Provider. Incumbent scoped for a region without incumbent',
					tags: ['incumbent', 'provider', 'scope']
				})
			}
		},
		autoSelectServiceProviderList: function () {
			const serviceProvidersLength = this.serviceProvidersFiltered.length
			if (serviceProvidersLength === 0) {
				this.errorService.report({
					message: 'No eligible providers',
					internalMessage: 'Error with Provider cardinality. No Service Provider exists for ServiceType in a Region or Service Provider has been filtered out.',
					tags: ['provider', 'configuration', 'serviceType', 'region']
				})
			} else if (serviceProvidersLength === 1) {
				return this.autoSelectProvider(this.serviceProvidersFiltered[0].id, this.serviceProvidersFiltered[0].name)
			} else {
				this.show = true
			}
		},
		autoSelectProvider: function (serviceProviderId, serviceProviderName) {
			this.setProviderId(serviceProviderId, true)
			this.setProviderName(serviceProviderName, true)
			this.serviceProviderSelected()
		},
		setProviderName: function (serviceProviderName, store = false) {
			this.serviceProviderName = serviceProviderName
			if (store) this.documentService.setServiceProviderName(this.serviceProviderName)
		},
		setProviderId: function (serviceProviderId, store = false) {
			this.serviceProviderId = serviceProviderId
			if (store) this.documentService.setServiceProviderId(this.serviceProviderId)
		},
		selected: function ({ id, name }) {
			if (!name || name === '') return
			this.serviceProviderName = name
			this.serviceProviderId = id
			this.isCustomProvider = false
			this.documentService.setServiceProviderId(id)
			this.documentService.setServiceProviderName(name)
		},
		customProviderSelected: function () {
			this.clearSelection()
			this.isCustomProvider = true
		},
		customProviderChanged: function (value) {
			if (value && value !== '') this.documentService.setServiceProviderName(this.serviceProviderName)
		},
		serviceProviderSelected: function () {
			this.show = false
			this.$emit('service-provider-selected')
		}
	}
}
</script>

<template>
	<div class="service-provider">
		<div class="service-provider__card" v-if="show">
			<div class="service-provider__label">
				<base-label modifiers="required height-25 margin-r" :value="$t('enrollment.required')"/>
				<div class="service-provider__title">{{ serviceProviderTitle }}</div>
			</div>
			<div class="service-provider__fields">
				<div class="service-provider__items">
					<div v-for="serviceProvider in serviceProvidersFiltered" :key="serviceProvider.id">
						<base-radio :name="`service-provider-select-${contentDocumentScan.id}`"
									class="service-provider__input"
									modifiers="light-label"
									:label="serviceProvider.name"
									:value="serviceProvider.id"
									@input="selected(serviceProvider)" />
					</div>
				</div>
				<div class="service-provider__all" v-if="showAllProviders">
					<base-radio :name="`service-provider-select-${contentDocumentScan.id}`"
								class="service-provider__input"
								modifiers="light-label"
								:label="$t('serviceProvider.other')"
								@input="customProviderSelected" />
					<base-input v-model="serviceProviderName" @input="customProviderChanged" v-if="isCustomProvider"/>
				</div>
			</div>
			<div class="service-provider__actions">
				<base-button modifiers="accent font-small small" class="service-provider__button" :disabled="!serviceProviderAvailable" @click="serviceProviderSelected">
					{{$t('serviceProvider.continue')}}
				</base-button>
			</div>
		</div>
		<error-messages v-if="showErrorMessages" :error-service="errorService"/>
	</div>
</template>

<style lang="scss" scoped>
.service-provider {
	&__card {
		background-color: var(--surface-colour);
		border: 1px solid #eee;
		border-radius: 5px;
		color: var(--on-surface);
		display: flex;
		flex-direction: column;
		padding: 1rem;
		width: 100%;
	}
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0;
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
	}
	&__fields {
		margin: 0.8rem 0;
		padding: 0 1rem;
	}
	&__items {
		position: relative;
		&--disabled {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 999;
			background-color: white;
			top: 0;
			left: 0;
			filter: blur(1px);
			opacity: 0.6;
		}
	}
	&__input {
		margin: 0.3rem 0;
		&--accent {
			color: var(--accent-colour);
		}
	}
	&__button {
		margin: 0.5rem 0;
	}
	&__actions {
		display: flex;
		justify-content: center;
	}
	&__hide {
		display: flex;
		justify-content: center;
	}
}
</style>
