<script>
import CustomerFieldSelect from '@/components/enrollment/fields/customer/auto-save/CustomerFieldSelect'
import incumbentPlanService from 'ocr-services/incumbent-plans.js'

export default {
	name: 'CustomerFieldIncumbentPlan',
	extends: CustomerFieldSelect,
	created: async function () {
		this.defaultValue = this.$t('enrollment.commonMsg.notApplicable')
		const regionId = this.enrollmentService.getRegionId()
		const incumbentPlans = (await incumbentPlanService.get(regionId))
		this.options = incumbentPlans.map(plan => {
			return { key: 'plan-id-' + plan.id, label: plan.label }
		})
		this._setDefaultValueKey()
	}
}
</script>
