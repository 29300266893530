<script>
import BaseLabel from 'lowfoot-components/BaseLabel'
import CustomerField from '@/components/enrollment/fields/customer/CustomerField'

export default {
	name: 'CustomerFieldAutoSave',
	extends: CustomerField,
	components: { BaseLabel },
	watch: {
		valid: async function (newValid) {
			if (this.customerFieldService) await this.customerFieldService.setValid(newValid)
		},
		isEnrollmentCreated: function (enrollmentCreated) {
			// auto-save hidden fields with pre-defined non-null default values
			if (enrollmentCreated && this.isHidden && this.defaultValue !== null) {
				this.value = this.defaultValue
				this.storeField()
			}
		}
	},
	methods: {
		cancel: function () {
			this.value = this.customerFieldService.getValue()
		},
		storeField: async function () {
			this.loading = true
			if (this.customerFieldService) {
				await this.customerFieldService.setValue(this.value)
				if (this.valid) await this.customerFieldService.saveCustomerField()
			}
			this.loading = false
		}
	}
}
</script>
