<script>
import { isEmpty } from '@/helpers/validate'
import CustomerFieldAutoSave from '@/components/enrollment/fields/customer/CustomerFieldAutoSave'

export default {
	name: 'CustomerFieldDate',
	extends: CustomerFieldAutoSave,
	props: {
		minYear: {
			type: Number,
			default: 1900
		}
	},
	data: (vm) => ({
		day: null,
		month: null,
		year: null,
		months: [],
		years: [],
		touched: false,
		patternError: vm.$t('commonMsg.missingIncompleteField')
	}),
	computed: {
		date: function () {
			if (!this.year || !this.month || !this.day) return null
			const stringDate = `${this.year}-${this.month}-${this.day}`
			const momentDate = this.$moment.utc(stringDate, 'YYYY-M-D', true)
			return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null
		},
		days: function () {
			if (!this.year || !this.month) return []
			const stringDate = `${this.year}-${this.month}`
			const daysInMonth = this.$moment(stringDate, 'YYYY-MM').daysInMonth()
			return this._range(1, daysInMonth)
		},
		valid: function () {
			if ((!this.dirty && !this.required)) return true
			let recentValue = this.date || this.value
			const momentValue = this.$moment(recentValue)
			return this._isMomentDateValid(momentValue)
		},
		edited: function () {
			if (this.date === null && (this.year || this.month || this.day)) {
				return true
			} else if (isEmpty(this.storedValue) && this.date === null) {
				return false
			} else {
				return this.date !== this.storedValue
			}
		},
		dirty: function () {
			return (this.edited && this.touched)
		}
	},
	watch: {
		value: function (newValue) {
			if (newValue == null) {
				this.day = this.month = this.year = null
			} else {
				if (this.valid) this._loadValue(newValue)
			}
		},
		date: function (value) {
			this._loadValue(value)
		}
	},
	mounted: async function () {
		this._loadValue(this.value)
		this.months = this._initializeMonths()
		this.years = this._initializeYears()
	},
	methods: {
		extractAttributes: function () {
			return null
		},
		_loadValue: function (value) {
			if (!value) return
			this.day = this.$moment(value).date()
			this.month = this.$moment(value).month() + 1
			this.year = this.$moment(value).year()
			this.value = value
		},
		_initializeMonths: function () {
			return this._range(1, 12)
		},
		_initializeYears: function () {
			const currentYear = this.$moment().year()
			return this._range(this.minYear, currentYear).reverse()
		},
		_isMomentDateValid: function (momentDate) {
			return (momentDate && momentDate.isValid())
		},
		_range: function (start, end) {
			return (new Array(end - start + 1))
				.fill(undefined)
				.map((_, i) => i + start)
		},
		onBlur: function (event) {
			const internalFocus = event.relatedTarget ? event.relatedTarget.classList.contains('field-date__input') : false
			if (!internalFocus) this.touched = true
		},
		onChange: async function () {
			if (this.valid) {
				this.value = this.date
				await this.storeField()
			}
		}
	}
}
</script>

<template>
	<form class="field-date" v-if="customerFieldType">
		<div class="field-date__label">
			<base-label v-if="showRequiredLabel"
						modifiers="required height-25 margin-r"
						:value="$t('enrollment.required')" />
			<base-label v-else-if="showOptionalLabel"
						modifiers="optional height-25 margin-r"
						:value="$t('enrollment.optional')" />
			<slot name="label"></slot>
		</div>
		<div class="field-date__fields">
			<div class="field-date__input-group">
				<div class="field-date__select-wrapper" :class="!valid && dirty ? 'field-date__select-wrapper--invalid' : ''">
					<select name="year" v-model="year" @change="onChange($event)" class="field-date__input" :disabled="readOnly" @blur="onBlur">
						<option disabled selected value=null style="display: none"></option>
						<option v-for="currentYear in years" :value="currentYear" :key="currentYear" class="cucumber-option-year"> {{currentYear}} </option>
					</select>
				</div>
				<div class="field-date__note">{{$t('enrollment.yearChar')}}</div>
			</div>
			<div class="field-date__input-group">
				<div class="field-date__select-wrapper" :class="!valid && dirty ? 'field-date__select-wrapper--invalid' : ''">
					<select name="month" v-model="month" @change="onChange($event)" class="field-date__input" :disabled="readOnly" @blur="onBlur">
						<option disabled selected value=null style="display: none"></option>
						<option v-for="currentMonth in months" :value="currentMonth" :key="currentMonth"> {{currentMonth}} </option>
					</select>
				</div>
				<div class="field-date__note">{{$t('enrollment.monthChar')}}</div>
			</div>
			<div class="field-date__input-group">
				<div class="field-date__select-wrapper" :class="!valid && dirty ? 'field-date__select-wrapper--invalid' : ''">
					<select name="day" v-model="day" @change="onChange($event)" class="field-date__input" :disabled="readOnly || days.length === 0" @blur="onBlur">
						<option disabled selected value style="display: none"></option>
						<option v-for="currentDay in days" :value="currentDay" :key="currentDay"> {{currentDay}} </option>
					</select>
				</div>
				<div class="field-date__note">{{$t('enrollment.dayChar')}}</div>
			</div>
		</div>
		<div v-if='!valid && dirty' class="field-date__error">{{ patternError }}</div>
	</form>
</template>

<style lang="scss" scoped>
.field-date {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
	& select {
		appearance: none;
		outline: 0;
		box-shadow:none;
		border-radius: 0;
		border: 0!important;
		background: white;
		cursor:pointer;
		width: 100%;
	}
	&__label {
		display: flex;
		align-items: center;
		margin: 0.5rem 0;
	}
	&__note {
		align-self: flex-end;
		font-weight: bold;
	}
	&__fields{
		display: flex;
	}
	&__input-group {
		display: flex;
		margin-right: 0.7rem;
	}
	&__select-wrapper {
		position: relative;
		display: flex;
		line-height: 3;
		background: white;
		overflow: hidden;
		border-radius: .25em;
		border: 1px solid var(--border-colour);
		min-width: 5rem;
		@media (max-width: 350px) {
			min-width: 4.8rem;
		}
		&::after {
			content: url("data:image/svg+xml;charset=UTF-8,<svg width='13' height='9' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M7.86994 10.0774L0.274733 2.48584C-0.0915776 2.11971 -0.0915776 1.52611 0.274733 1.16002L1.16059 0.274591C1.52628 -0.0909172 2.11895 -0.0916206 2.48549 0.273028L8.53319 6.28947L14.5808 0.273028C14.9474 -0.0916206 15.5401 -0.0909172 15.9057 0.274591L16.7916 1.16002C17.1579 1.52615 17.1579 2.11975 16.7916 2.48584L9.19644 10.0774C8.83013 10.4435 8.23625 10.4435 7.86994 10.0774Z' fill='white'/> </svg>");
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 5px;
			background: var(--accent-colour);
			color: white;
			cursor: pointer;
			pointer-events: none;
			-webkit-transition: 0.25s all ease;
			transition: 0.25s all ease;
			height: 100%;
			font-size: 0.55rem;
			line-height: 5;
		}
	}
	&__select-wrapper--invalid {
		border: solid 2px red !important;
		select {
			background-color: #fff5db !important;
		}
	}
	&__input {
		font-size: 1.1rem;
		text-overflow: ellipsis;
		padding: 0.3rem;
		border: solid 1px var(--border-colour);
		border-radius: 4px;
		height: 2.5rem;
		margin-right: 0.25rem;
		&:disabled {
			background-color: #EEE;
			color: #575757;
		}
	}
	&__error {
		bottom: -20px;
		color: red;
		font-size: 0.8rem;
		margin-top: 0.25rem;
	}
}
</style>
