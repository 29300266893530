<script>
import BaseLabel from 'lowfoot-components/BaseLabel'
import BaseModal from 'lowfoot-components/BaseModal'
import RegionStore from '@/models/Region'
import EnrollmentService from '@/services/EnrollmentService'

export default {
	name: 'Region',
	components: {
		BaseLabel,
		BaseModal
	},
	props: [ 'readOnly' ],
	inject: ['injectedServices'],
	data: (vm) => ({
		brandRegions: [],
		injectedServices: vm.injectedServices
	}),
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		regions: function () {
			return RegionStore.all()
		},
		hideRegions: function () {
			return !!this.selectedRegionId
		},
		showRegionSelection: function () {
			return this.regions.length > 1 && !this.hideRegions
		},
		showMapCallout: function () {
			return this.enrollmentService && !this.enrollmentService.isIncumbentOnly()
		},
		regionReselectMessage: function () {
			const selectedName = this.$t('region.' + this.selectedRegionCode)
			if (this.enrollmentService.isIncumbentOnly()) {
				return this.$t('region.selected.provider', { provider: selectedName })
			} else {
				return this.$t('region.selected.area', { region: selectedName })
			}
		},
		selectedRegion: function () {
			const regions = this.regions
			const regionId = this.selectedRegionId
			return regions.find((region) => region.id === regionId)
		},
		selectedRegionCode: function () {
			const region = this.selectedRegion
			return region ? region.code : ''
		},
		selectedRegionId: function () {
			const enrollmentService = this.enrollmentService
			return enrollmentService ? enrollmentService.getRegionId() : null
		}
	},
	created: async function () {
		if (this.regions.length === 1) {
			await this.updateRegion(this.regions[0].id)
		}
	},
	methods: {
		updateRegion: async function (regionId) {
			await this.enrollmentService.setRegionId(regionId)
			this.$emit('region-selected', true)
		},
		splitRegionColumns: function (column) {
			return this.regions.filter((r, index) => index % 2 === column)
		},
		resetEnrollment: async function () {
			await EnrollmentService.reset()
			this.$modal.hide('modalRestart')
			location.reload()
		}
	}
}
</script>
<template>
	<div class="region">
		<div v-show="showRegionSelection">
			<div class="region__label">
				<base-label modifiers="required height-25 margin-r" :value="$t('enrollment.required')"/>
				<div class="region__title">{{$t('enrollment.region.select')}}</div>
			</div>
			<div class="region__selection-list">
				<div class="region__selection-wrapper" v-for="column in [0,1]" :key="column">
					<div class="region__selection" v-for="region in splitRegionColumns(column)" :key="region.id">
						<input type="radio"
								:name="region.code"
								:id="region.code"
								:value="region.code"
								:disabled="readOnly"
								@change="updateRegion(region.id)"/>
						<label class="region__value--radio" :for="region.code" >{{ $t('region.' + region.code) }}</label>
					</div>
				</div>
			</div>
			<div class="region__map-callout" v-show="showMapCallout">
				{{ $t('region.mapCallout') }}
				<span class="region__map-callout-link" @click="$modal.show('modalMapIllustration')">
						{{ $t('commonMsg.clickHere') }}
				</span>
			</div>
		</div>
		<div class="region__information" v-if="hideRegions">
			<div class="region__subtitle">
				<div class="region__message" v-html="regionReselectMessage"></div>
				<base-button modifiers="accent-inverse font-small small"
					class="region__button"
					@click="$modal.show('modalRestart')">{{ $t('region.restart') }}</base-button>
			</div>
		</div>
		<base-modal :modal-name="'modalMapIllustration'"
					:adaptive="true"
					:height="'auto'"
					:clickToClose="true"
					:width="'95%'"
					:max-width="510"
					modifiers="center flex-column padding-1rem">
			<base-button class="region__modal--close" modifiers="accent circle xxxs-circle" @click.native="$modal.hide('modalMapIllustration')">
				<font-awesome-icon :icon="['fas', 'times']" />
			</base-button>
			<div>
				<img class="region__map-callout-illustration"
					src="https://d34u1lqoewzpy6.cloudfront.net/img/region_map.png" alt="Provider region map" />
			</div>
		</base-modal>
		<base-modal :modal-name="'modalRestart'"
					:adaptive="true"
					:height="'auto'"
					:clickToClose="false"
					:width="'95%'"
					:max-width="510"
					modifiers="center flex-column padding-1rem">
			<base-button class="region__modal--close" modifiers="accent circle xxxs-circle" @click.native="$modal.hide('modalRestart')">
				<font-awesome-icon :icon="['fas', 'times']" /></base-button>
			<div class="region__modal--message">{{ $t('region.warning') }}</div>
			<base-button modifiers="accent-inverse font-small small"
				class="region__modal--button"
				@click=resetEnrollment()>{{ $t('region.continueButton') }}</base-button>
		</base-modal>

	</div>
</template>
<style lang="scss" scoped>
.region {
	background-color: var(--surface-colour);
	border: 1px solid var(--border-variant-light);
	border-radius: var(--border-radius);
	color: (--on-surface);
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	&__information {
		display: flex;
		flex-direction: column;
	}
	&__selection-list {
		display: flex;
		justify-content: center;
		margin: 1rem auto;
	}
	&__selection-wrapper {
		display: flex;
		flex-direction: column;
		flex-flow: column wrap;
		align-content: space-evenly;
		width: 40%;
		@media (max-width: 500px) {
			width: 100%;
		}
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
	}
	&__message {
		font-size: var(--font-size-caption);
		font-weight: var(--font-weight-caption);
		letter-spacing: var(--letter-spacing-regular);
		margin: 0.5rem;
	}
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0;
	}
	&__subtitle {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 0.5rem 0;
	}
	&__selection {
		display: flex;
		justify-content: left;
		align-items: center;
		padding: 0.2rem 0;
		min-width: 120px;
		max-height: 65px;
		& > * {
			cursor: pointer;
		}
	}
	&__button {
		margin-top: 1rem;
	}
	&__map-callout {
		color: red;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		&-illustration {
			width: 100%;
		}
		&-link {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	&__modal {
		&--message {
			margin: 1.5rem 1rem;
			font-size: 1rem;
		}
		&--button {
			margin-top: 1rem;
		}
		&--close {
			align-self: flex-start;
		}
	}
	&__value, &__input {
		font-size: 1.1rem;
		max-width: 280px;
		text-overflow: ellipsis;
		flex: 2;
		@media (max-width: 750px) {
			width: 100%;
		}
		@media (max-width: 550px) {
			flex: 1 0 95%;
			overflow-wrap: anywhere;
		}
		&--radio {
			padding-left: 0.5rem;
		}
	}
}
</style>
