export class Condition {
	name: String
	value: Boolean

	constructor (name, value) {
		this.name = name
		this.value = value
	}
}

export default class PreconditionService {
	conditions: Array<Condition>

	constructor (names) {
		this.conditions = []
		this.initConditions(names)
	}

	initConditions (names) {
		for (const name of names) {
			this.saveResult(name)
		}
	}

	conditionFulfilled (name) {
		this.saveResult(name, true)
	}

	conditionDenied (name) {
		this.saveResult(name, false)
	}

	saveResult (name, value = false) {
		const condition = this.findCondition(name)
		condition ? condition.value = value : this.conditions.push(new Condition(name, value))
	}

	findCondition (name) {
		return this.conditions.find(condition => condition.name === name)
	}

	arePreconditionsFulfilled () {
		return this.conditions.every(condition => condition.value)
	}
}
