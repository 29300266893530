<script>
import DocumentField from '@/components/enrollment/fields/document/DocumentField'
import BaseInput from 'lowfoot-components/BaseInput'
import BaseLabel from 'lowfoot-components/BaseLabel'
import { isEmpty } from '@/helpers/validate.js'
import debounce from 'lodash/debounce'

export default {
	name: 'DocumentFieldText',
	extends: DocumentField,
	components: { BaseInput, BaseLabel },
	data: () => ({
		debouncedSubmit: null
	}),
	computed: {
		valid: function () {
			if (isEmpty(this.value)) return !this.required
			return isEmpty(this.pattern) ||
				(this.value.match(this.pattern) !== null) ||
				(this.maskedInputField && this.maskedInputField.value.match(this.pattern) !== null)
		},
		dataType: function () {
			return this.documentFieldType ? this.documentFieldType.dataType : null
		},
		mask: function () {
			return this.documentFieldType ? this.documentFieldType.mask : null
		},
		maskedInputField: function () {
			return (this.$refs.maskeddocumentfield &&
				this.$refs.maskeddocumentfield.$children &&
				this.$refs.maskeddocumentfield.$children[0])
				? this.$refs.maskeddocumentfield.$children[0].$el : null
		},
		inputMode: function () {
			// i-mask-component needs a numeric keyboard to avoid IME double characters
			return (this.mask && this.mask !== '') ? 'tel' : 'text'
		}
	},
	mounted: async function () {
		this.initialize()
		this.documentTextFieldMounted()
		if (this.valid) await this.storeField()
	},
	methods: {
		documentTextFieldMounted: function () {
			this.debouncedSubmit = debounce(() => {
				this.save()
			}, 2000)
		},
		extractAttributes: function () {
			this.pattern = this.documentFieldType.validPattern
		},
		save: async function () {
			if (!this.edited) return
			if (this.valid) await this.storeField()
		},
		handleValidity: function (event) {
			if (event && event.target && this.$refs.maskeddocumentfield) {
				let element = this.maskedInputField
				if (element && !isEmpty(this.pattern) && !element.hasAttribute('pattern')) {
					element.setAttribute('pattern', this.pattern)
				}
				if (event.target.validity && !event.target.validity.valid) {
					this.debouncedSubmit.cancel()
				}
			}
			return this.valid
		},
		handleKeyUp: function (event) {
			// On a key up event, check if input is valid, and if enter key is pressed then flush debounce submit
			if (this.handleValidity(event)) {
				event.key === 'Enter' ? this.debouncedSubmit.flush() : this.debouncedSubmit()
			}
		},
		handleInput: function (event) {
			// On an input event, check if input is valid and debounce submit
			if (this.handleValidity(event)) this.debouncedSubmit()
		},
		handleBlur: function (event) {
			// On a blur event, check if input is valid and flush debounce submit
			if (this.handleValidity(event)) this.debouncedSubmit.flush()
		}
	}
}
</script>

<template>
	<form class="document-field-text" @submit.capture.prevent="">
		<div class="document-field-text__label">
			<base-label v-if="showRequiredLabel"
						modifiers="required height-25 margin-r"
						:value="$t('enrollment.required')" />
			<base-label v-else-if="showOptionalLabel"
						modifiers="optional height-25 margin-r"
						:value="$t('enrollment.optional')" />
			<slot name="label"></slot>
		</div>
		<div class="document-field-text__input-options">
			<div class="document-field-text__input-group">
				<base-input  class="document-field-text__input"
						v-model.trim="value"
						:inputmode="inputMode"
						:disabled="readOnly"
						:invalid="isInvalid()"
						:mask="mask"
						:name="attributeName"
						:required="required"
						:unmask="true"
						ref="maskeddocumentfield"
						@blur="handleBlur($event)"
						@keyup="handleKeyUp($event)"
						@input="handleInput($event)"
						@change="handleInput($event)"
						@invalid.capture.prevent="" />
				<div class="document-field-text__spinner" v-show="valid && edited">
					<font-awesome-icon :icon="['fas', 'spinner']" class="fa-spin" spin />
				</div>
			</div>
			<div class="document-field-text__icon"  @click="cancel()" v-show="!valid && edited && documentField.value && brandingUi.showResetButton">
				<font-awesome-icon :icon="['fas', 'undo']"/>
			</div>
		</div>
		<div class="document-field-text__error" v-show="isInvalid()">{{ patternError }}</div>
	</form>
</template>

<style lang="scss" scoped>
.document-field-text {
	flex-grow: 1;
	flex-direction: column;
	width: 100%;
	position: relative;
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0;
	}
	&__input {
		font-size: 1.1rem;
		text-overflow: ellipsis;
		height: 2.5rem;
		&-options {
			display: flex;
			align-items: center;
			width: 100%;
		}
		&-group {
			flex-grow: 1;
			position: relative;
		}
	}
	&__icon {
		padding-left: 0.5rem;
	}
	&__error {
		color: red;
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-top: 0.25rem;
	}
	&__spinner {
		position: absolute;
		z-index: 10;
		right: 2%;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0.9;
		transition: .5s ease;
	}
}
</style>
