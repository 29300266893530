<script>
import BaseInput from 'lowfoot-components/BaseInput'
import CustomerField from '@/components/enrollment/fields/customer/CustomerField'
import { isEmpty } from '@/helpers/validate.js'

export default {
	name: 'CustomerFieldTextSimple',
	extends: CustomerField,
	components: { BaseInput },
	props: [ 'invalid', 'isDuplicateField', 'enableDuplicateFieldValidation' ],
	computed: {
		valid: function () {
			if (isEmpty(this.value)) return !this.required
			return isEmpty(this.pattern) || (this.value.match(this.pattern) !== null)
		},
		dataType: function () {
			return this.customerFieldType && this.customerFieldType.customerFieldCategory.dataType
		},
		mask: function () {
			return this.customerFieldType && this.customerFieldType.mask
		},
		inputMode: function () {
			// i-mask-component needs this to avoid IME double characters
			return (this.mask && this.mask !== '') ? 'tel' : 'text'
		},
		isDuplicateFieldInvalid: function () {
			return this.invalid || this.isInvalid
		},
		validateDuplicate: function () {
			return this.isDuplicateField && this.enableDuplicateFieldValidation
		},
		showInvalidState: function () {
			return this.isDuplicateField
				? this.isDuplicateFieldInvalid && this.validateDuplicate
				: this.isInvalid
		},
		showPatternError: function () {
			return this.isInvalid && !this.isDuplicateField
		}
	},
	methods: {
		extractAttributes: function () {
			this.pattern = this.customerFieldType.validPattern
		},
		handleKeyUp: function (event) {
			this.$emit('keyUp', event)
		},
		handleInput: function (event) {
			this.$emit('input', event)
		},
		handleBlur: function (event) {
			this.$emit('blur', event)
		}
	}
}
</script>

<template>
	<form class="customer-field-simple-text" @submit.capture.prevent="" v-if="customerFieldType">
		<div class="customer-field-simple-text__input-options">
			<div class="customer-field-simple-text__input-group">
				<base-input class="customer-field-simple-text__input"
							v-model="value"
							:inputmode="inputMode"
							:disabled="readOnly"
							:invalid="showInvalidState"
							:mask="mask"
							:name="attributeName"
							:required="required"
							@blur="handleBlur($event)"
							@keyup="handleKeyUp($event)"
							@input="handleInput($event)"
							@change="handleInput($event)"
							@invalid.capture.prevent=""/>
			</div>
		</div>
		<div class="customer-field-simple-text__error" v-show="showPatternError ">{{ patternError }}</div>
	</form>
</template>

<style lang="scss" scoped>
.customer-field-simple-text {
	flex-grow: 1;
	flex-direction: column;
	width: 100%;
	position: relative;
	&__input {
		font-size: 1.1rem;
		text-overflow: ellipsis;
		height: 2.5rem;
		&-options {
			display: flex;
			align-items: center;
			width: 100%;
		}
		&-group {
			flex-grow: 1;
			position: relative;
		}
	}
	&__error {
		color: red;
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-top: 0.25rem;
	}
}
</style>
