<script>
import DocumentFieldText from '@/components/enrollment/fields/document/DocumentFieldText'
import occtoFieldMixin from '@/mixins/occto-field-mixin'

export default {
	name: 'DocumentFieldOccto',
	extends: DocumentFieldText,
	mixins: [ occtoFieldMixin ],
	watch: {
		value: async function () {
			if (this.existingCustomerDetection) {
				await this.documentService.updateDocumentFieldValid(this.documentField, false)
			}
		}
	},
	methods: {
		_updateValid: async function () {
			if (!this.existingCustomerDetection) return
			const valid = !this.facilityIsExistingCustomer && this.facility != null
			await this.documentService.updateDocumentFieldValid(this.documentField, valid)
		}
	}
}
</script>
