<script>
import BrandingService from '@/services/BrandingService'
import { isEmpty } from '@/helpers/validate'
import BaseLabel from 'lowfoot-components/BaseLabel'
import Noty from 'noty'
import CustomerFieldService from '@/services/CustomerFieldService'

export default {
	name: 'CustomerField',
	components: { BaseLabel },
	props: {
		customerFieldType: {
			type: Object
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	inject: ['injectedServices', 'injectedGroupServices', 'injectedCardServices'],
	data: (vm) => ({
		brandingUi: {},
		required: false,
		attributeName: '',
		loading: false,
		pattern: '',
		value: '',
		defaultValue: null,
		patternError: vm.$t('commonMsg.missingIncompleteField'),
		customerFieldService: null
	}),
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		contentGroupService: function () {
			return this.injectedGroupServices ? this.injectedGroupServices.contentGroupService : null
		},
		cardService: function () {
			return this.injectedCardServices ? this.injectedCardServices.cardService : null
		},
		isEnrollmentCreated: function () {
			return this.enrollmentService.enrollmentCreated()
		},
		storedValue: function () {
			return this.customerFieldService ? this.customerFieldService.getValue() : null
		},
		edited: function () {
			if (this.storedValue) {
				return this.customerFieldService.isDirty() || this.value !== this.storedValue
			} else {
				return !isEmpty(this.value)
			}
		},
		showRequiredLabel: function () {
			return this.brandingUi.showRequiredLabel && this.required
		},
		showOptionalLabel: function () {
			return this.brandingUi.showOptionalLabel && !this.required
		},
		isInvalid: function () {
			return !this.valid && this.edited
		},
		isHidden: function () {
			return this.customerFieldService && this.customerFieldService.isHidden()
		},
		isToggleField: function () {
			return this.customerFieldService && this.customerFieldService.isToggleField()
		}
	},
	watch: {
		valid: function (newValid) {
			this.$emit('validityChanged', newValid)
		},
		edited: function (newEdited) {
			this.$emit('editChanged', newEdited)
		},
		value: async function (newValue) {
			if (newValue && this.isToggleField) {
				// trigger group reactivity
				this.customerFieldService.setValue(newValue)
			}
			if (newValue === '' && this.storedValue && this.valid) {
				// reset fields that are allowed to be empty e.g. optional fields
				this.value = this.defaultValue
				this.storeField()
			}
			this.$emit('valueChanged', newValue)
		},
		isHidden: function (newHiddenValue) {
			if (this.isEnrollmentCreated && this.value && newHiddenValue) {
				this.value = this.defaultValue
				this.storeField()
			}
		}
	},
	created: function () {
		this.customerFieldService =
			new CustomerFieldService(this.enrollmentService, this.customerFieldType.id, this.contentGroupService)
	},
	mounted: async function () {
		await this.initialize()
		this.setErrorMessages()
	},
	methods: {
		initialize: async function () {
			this.brandingUi = BrandingService.get().getUi()
			await this.extractDefaultAttributes()
		},
		extractDefaultAttributes: async function () {
			this.value = this.customerFieldService.getValue()
			this.required = !this.customerFieldType.optional
			await this.customerFieldService.setValid(this.valid)
			if (this.hasOwnProperty('extractAttributes')) this.extractAttributes()
		},
		setErrorMessages: function () {
			let customErrorMessage = this.customerFieldType.errorMessage
			let customGenericMessage = this.$t(`commonMsgCMS.missingIncompleteField`)
			if (customErrorMessage) {
				this.patternError = customErrorMessage
			} else if (customGenericMessage && !(customGenericMessage === '' || /commonMsg/.test(customGenericMessage.toString()))) {
				// Check generic custom message has been configured otherwise use default
				this.patternError = customGenericMessage
			}
		},
		storeField: async function () {
			return true
		},
		showMessage: function (message) {
			Noty.closeAll()
			new Noty({
				text: message,
				layout: 'bottomCenter',
				timeout: 2000
			}).show()
		}
	}
}
</script>
