import rg4js from 'raygun4js'
import * as Sentry from '@sentry/vue'

class Error {
	message: String
	internalMessage: String
	tags: Array<any>
	code: String

	constructor (message, tags = [], internalMessage = null!, code = null!) {
		this.message = message
		this.tags = tags
		this.internalMessage = internalMessage
		this.code = code
	}
}

export default class ErrorService {
	errors: Array<Error>

	constructor () {
		this.errors = []
	}

	hasErrors () {
		return this.errors.length > 0
	}

	report ({ message, tags = [], internalMessage = null!, code = null!, raygunIt = true }) {
		const error = new Error(message, tags, internalMessage, code)
		this.errors.push(error)
		Sentry.captureException('Error Service: ' + message)
		if (raygunIt) this.raygunSend(error)
	}

	raygunSend (currentError) {
		const error = currentError.internalMessage || currentError.message
		const tags = currentError.tags
		rg4js('send', { error, tags })
		console.error(error)
	}
}
