<script>
import CustomerFieldText from '@/components/enrollment/fields/customer/auto-save/CustomerFieldText'
import CustomerFieldSelect from '@/components/enrollment/fields/customer/auto-save/CustomerFieldSelect'
import CustomerFieldBirthday from '@/components/enrollment/fields/customer/auto-save/CustomerFieldBirthday'
import CustomerFieldDate from '@/components/enrollment/fields/customer/auto-save/CustomerFieldDate'
import CustomerFieldRadio from '@/components/enrollment/fields/customer/auto-save/CustomerFieldRadio'
import CustomerFieldDuplicated from '@/components/enrollment/fields/customer/auto-save/CustomerFieldDuplicated'
import CustomerFieldPhone from '@/components/enrollment/fields/customer/auto-save/CustomerFieldPhone'
import CustomerFieldIncumbentPlan from '@/components/enrollment/fields/customer/auto-save/CustomerFieldIncumbentPlan'
import CustomerFieldFacility from '@/components/enrollment/fields/customer/auto-save/CustomerFieldFacility'

export default {
	name: 'CustomerFieldType',
	props: ['customerFieldType'],
	components: {
		CustomerFieldBirthday,
		CustomerFieldDate,
		CustomerFieldIncumbentPlan,
		CustomerFieldDuplicated,
		CustomerFieldPhone,
		CustomerFieldRadio,
		CustomerFieldSelect,
		CustomerFieldText,
		CustomerFieldFacility
	},
	computed: {
		customerFieldCategoryCode: function () {
			return this.customerFieldType && this.customerFieldType.customerFieldCategory
				? this.customerFieldType.customerFieldCategory.code : null
		},
		isCustomerFieldDuplicated: function () {
			return this.customerFieldType && this.customerFieldType.enableDuplicate
		},
		customerFieldComponent: function () {
			switch (this.customerFieldCategoryCode) {
				case 'phone':
					return 'CustomerFieldPhone'
				case 'incumbent_plan':
					return 'CustomerFieldIncumbentPlan'
				case 'radio_select':
					return 'CustomerFieldRadio'
				case 'dropdown':
					return 'CustomerFieldSelect'
				case 'birthday':
					return 'CustomerFieldBirthday'
				case 'date':
					return 'CustomerFieldDate'
				case 'manual_location_id':
					return 'CustomerFieldFacility'
				default:
					return 'CustomerFieldText'
			}
		},
		customerFieldSelector: function () {
			return this.isCustomerFieldDuplicated ? 'CustomerFieldDuplicated' : this.customerFieldComponent
		},
		duplicatedFieldName: function () {
			return this.isCustomerFieldDuplicated ? this.customerFieldComponent : null
		},
		isHeader: function () {
			if (!this.customerFieldType) return false
			const isRadioField = this.customerFieldCategoryCode === 'radio_select'
			const isOptional = this.customerFieldType.optional
			const hasOptions = this.customerFieldType.options && this.customerFieldType.options.length > 0
			return isRadioField && isOptional && !hasOptions
		}
	}
}
</script>
<template>
	<component :is="customerFieldSelector"
		v-if="customerFieldType"
		v-bind="$attrs"
		v-on="$listeners"
		:customer-field-type="customerFieldType"
		:duplicated-field-name="duplicatedFieldName">
		<template v-slot:label>
			<div v-if="isHeader" class="customer-field-type__label customer-field-type__label--bold">
				<slot name="label" />
			</div>
			<slot v-else name="label" />
		</template>
	</component>
</template>
<style lang="scss" scoped>
.customer-field-type {
	&__label {
		&--bold {
			::v-deep .content-item__label {
				font-size: 1.05rem;
				font-weight: var(--font-weight-body-2);
				letter-spacing: var(--letter-spacing-regular);
				margin: 0.5rem 0 0 0;
			}
		}
	}
}
</style>
