<script>
import CustomerFieldOccto from '@/components/enrollment/fields/customer/auto-save/CustomerFieldOccto'
import Facility from '@/components/enrollment/documents/Facility'
import BrandingService from '@/services/BrandingService'

export default {
	name: 'CustomerFieldFacility',
	components: { CustomerFieldOccto, Facility },
	props: ['customerFieldType'],
	data: () => ({
		brandingUi: {},
		fieldLoaded: false
	}),
	computed: {
		showOcctoFacilityData: function () {
			return this.brandingUi && this.brandingUi.showOcctoFacilityData && this.fieldLoaded
		}
	},
	created: function () {
		this.brandingUi = BrandingService.get().getUi()
	}
}
</script>
<template>
	<div>
		<customer-field-occto :customer-field-type="customerFieldType" @loaded="fieldLoaded = $event">
			<template v-slot:label>
				<slot name="label"></slot>
			</template>
		</customer-field-occto>
		<facility class="customer-field-facility__display" v-show="showOcctoFacilityData" :loading="!fieldLoaded" />
	</div>
</template>
<style lang="scss" scoped>
.customer-field-facility {
	&__display {
		margin-top: 1rem;
	}
}
</style>
