<script>
import BrandingService from '@/services/BrandingService'
import Region from '@/components/enrollment/preconditions/Region'
import ServiceProvider from '@/components/enrollment/preconditions/ServiceProvider'
import PreconditionService from '@/services/PreconditionService'
import { mapGetters } from 'vuex'

export default {
	name: 'Preconditions',
	components: { Region, ServiceProvider },
	inject: ['injectedServices'],
	data: (vm) => ({
		brandingConfig: {},
		injectedServices: vm.injectedServices,
		preconditionService: null
	}),
	computed: {
		...mapGetters({
			preconditionsCheckpoint: 'cms/getPreconditionsCheckpoint'
		}),
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		preconditionsFulfilled: function () {
			if (!this.preconditionService) return false
			return this.preconditionService.arePreconditionsFulfilled()
		},
		showRegion: function () {
			return this.brandingConfig.ui && this.brandingConfig.ui.showRegion
		},
		isRegionSelected: function () {
			return !!this.enrollmentService.getRegionId()
		},
		showProviderSelection: function () {
			return this.isRegionSelected && !this.preconditionsCheckpoint
		},
		contentDocumentScans: function () {
			return this.enrollmentService.getContentDocumentScans() || []
		}
	},
	created: function () {
		this.brandingConfig = BrandingService.get().getConfig()
	},
	mounted: function () {
		this.initialize()
	},
	watch: {
		preconditionsFulfilled: function (value) {
			if (value) this.$store.commit('cms/setPreconditionsCheckpoint', true)
		}
	},
	methods: {
		conditionFulfilled: function (name) {
			this.preconditionService.conditionFulfilled(name)
		},
		initialize: function () {
			const serviceProviders = this.contentDocumentScans.map(contentDocumentScan => `serviceProvider${contentDocumentScan.id}`)
			this.preconditionService = new PreconditionService(['region', ...serviceProviders])
		}
	}
}
</script>

<template>
	<div class="preconditions" v-if="enrollmentService && preconditionService">
		<div class="preconditions__region-form" v-show="showRegion">
			<region @region-selected="conditionFulfilled('region')" />
		</div>
		<div v-if="isRegionSelected">
			<div class="preconditions__service-provider" v-show="showProviderSelection">
				<service-provider v-for="contentDocumentScan in contentDocumentScans"
					:key="contentDocumentScan.id"
					:contentDocumentScan="contentDocumentScan"
					@service-provider-selected="conditionFulfilled(`serviceProvider${contentDocumentScan.id}`)" />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.preconditions {
	&__region-form {
		margin-bottom: 0.5rem;
		width: 100%;
	}
}
</style>
