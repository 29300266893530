<script>
import DocumentFieldText from '@/components/enrollment/fields/document/DocumentFieldText'
import DocumentFieldOccto from '@/components/enrollment/fields/document/DocumentFieldOccto'
import processingDocumentService from 'ocr-services/processing-documents.js'
import BrandingService from '@/services/BrandingService'
import { isEmpty } from '@/helpers/validate'
import Facility from './Facility'

export default {
	name: 'ProcessingDocument',
	props: ['required', 'processingDocument', 'readOnly'],
	inject: ['injectedServices', 'injectedDocumentService'],
	components: { Facility, DocumentFieldText, DocumentFieldOccto },
	data: (vm) => ({
		imageBase64: null,
		brandingUi: {},
		injectedServices: vm.injectedServices,
		fieldsLoaded: false
	}),
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		documentService: function () {
			return this.injectedDocumentService.documentService
		},
		documentFields: function () {
			return this.documentService ? this.documentService.getDocumentFields() : null
		},
		showOcctoFacilityData: function () {
			return this.brandingUi && this.brandingUi.showOcctoFacilityData && this.documentService.getServiceType().code === 'ELECTRIC'
		},
		serviceTypeCode: function () {
			return this.documentService ? this.documentService.getServiceType().code.toLowerCase() : ''
		},
		areDocumentFieldsAvailable: function () {
			return (this.documentFields) && (this.documentFields.length > 0)
		},
		showNoResultsMessage: function () {
			return this.areDocumentFieldsAvailable && this.documentFields.every(documentField => isEmpty(documentField.value))
		}
	},
	created: function () {
		this.brandingUi = BrandingService.get().getUi()
	},
	watch: {
		'processingDocument.id': {
			handler: function (newVal) {
				// prevent unnecessary reload of image data and set new processing document to the service
				if (newVal && this.documentService && this.processingDocument.status === 'COMPLETE') {
					this.documentService.setProcessingDocument(this.processingDocument)
					this._setImage(this.processingDocument.id)
				}
			},
			immediate: true
		}
	},
	methods: {
		documentFieldComponent: function (documentField) {
			if (!documentField.documentFieldType) return ''
			const isTextField = this._isDocumentField(documentField, 'TEXT') ||
				this._isDocumentField(documentField, 'TEXT_NUMERIC')
			const isOCCTOField = this._isOCCTOField(documentField)
			if (isOCCTOField) return 'DocumentFieldOccto'
			if (isTextField) return 'DocumentFieldText'
			return ''
		},
		_isOCCTOField: function (documentField) {
			return documentField.documentFieldType.documentFieldCategory.code === 'ELECTRIC_LOCATION_ID'
		},
		_isDocumentField: function (documentField, dataType) {
			return documentField.documentFieldType.dataType === dataType
		},
		_setImage: async function (processingDocumentId) {
			this.imageBase64 = await processingDocumentService.getImageBase64(processingDocumentId)
		}
	}
}
</script>
<template>
	<div class="document-type">
		<div class="document-type__error" v-show="showNoResultsMessage">
			{{ $t('documents.error.badScan') }}
		</div>
		<div class="document-type__field" v-for="documentField in documentFields" :key="documentField.id">
			<component :is="documentFieldComponent(documentField)"
				v-bind="$attrs"
				v-on="$listeners"
				:required="required"
				:read-only="readOnly"
				:document-field="documentField"
				:document-service="documentService"
				@loaded="fieldsLoaded = true">
				<template v-slot:label>
					<div class="document-type__label">
						{{ $t(`documentField.${serviceTypeCode}.${documentField.documentFieldType.name}.label`) }}
					</div>
				</template>
			</component>
		</div>
		<facility v-show="showOcctoFacilityData" :loading="!fieldsLoaded"/>
		<div class="document-type__image" v-if="imageBase64" :style="'background-image: url(' + imageBase64 + ')'"></div>
	</div>
</template>
<style lang="scss" scoped>
.document-type {
	width: 100%;
	&__field {
		margin: 1rem 0;
	}
	&__image {
		width: 100%;
		height: 200px;
		background-position: center;
		background-size: cover;
		border-radius: var(--border-radius);
		margin: 1rem 0;
	}
	&__error {
		color: red;
		margin: 1rem 0;
	}
}
</style>
