<script>
import marked from 'marked'

export default {
	name: 'CustomContent',
	props: [ 'customContent' ],
	computed: {
		content: function () {
			switch (this.customContent.contentType) {
				case 'MARKDOWN':
					return marked(this.customContent.content)
				case 'HTML':
					return this.customContent.content
				default:
					return null
			}
		}
	}
}
</script>
<template>
	<div class="custom-content" v-if="content" >
		<div class="custom-content__content" v-html="content"></div>
	</div>
</template>

<style lang="scss" scoped>
.custom-content {
	&__content {
		word-wrap: break-word;
		::v-deep p, ::v-deep h1, ::v-deep h2, ::v-deep h3, ::v-deep li {
			margin: 0.5rem 0;
		}
		::v-deep a {
			text-decoration: underline;
		}
		::v-deep ol, ::v-deep ul {
			list-style: circle;
			margin-left: 1.2rem;
		}
	}
}
</style>
