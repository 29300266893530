<script>
import ContentDocumentScan from '@/components/enrollment/documents/ContentDocumentScan'
import ContentGroup from '@/components/enrollment/cards/ContentGroup'
import CustomContent from './CustomContent'
import CustomerFieldType from '@/components/enrollment/fields/customer/CustomerFieldType'

export default {
	name: 'ContentItem',
	components: {
		ContentDocumentScan,
		ContentGroup,
		CustomContent,
		CustomerFieldType
	},
	props: ['contentItem'],
	inject: ['injectedServices'],
	data: (vm) => ({
		injectedServices: vm.injectedServices
	}),
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		isEnrollmentComplete: function () {
			return this.enrollmentService && this.enrollmentService.isComplete()
		},
		type: function () {
			return this.contentItem.contentType
		},
		customerFieldType: function () {
			return this.contentItem.customerFieldType
		},
		isContentItemEmpty: function () {
			return !(this.contentItem.customerFieldType || this.contentItem.contentDocumentScan ||
				this.contentItem.contentGroupId || this.contentItem.customContent)
		}
	},
	mounted () {
		if (this.isContentItemEmpty) this.$router.push({ name: 'error' })
	}
}
</script>
<template>
	<div class="content-item" :class="type === 'documentGroup' ? 'content-item--document' : 'content-item--field' ">
		<div class="content-item__custom_content" v-show="type === 'CUSTOM_CONTENT'">
			<custom-content v-if="contentItem.customContent" :custom-content="contentItem.customContent"/>
		</div>
		<div class="content-item__content-group" v-show="type === 'CONTENT_GROUP'">
			<content-group v-if="contentItem.contentGroupId" :content-group-id="contentItem.contentGroupId" />
		</div>
		<div class="content-item__document-groups" v-show="type === 'DOCUMENT_SCAN'">
			<content-document-scan v-if="contentItem.contentDocumentScan"
				class="content-item__document-scan"
				:read-only="isEnrollmentComplete"
				:content-document-scan="contentItem.contentDocumentScan">
				<template v-slot:label>
					<div class="content-item__label content-item__label--document">{{ contentItem.label }}</div>
				</template>
			</content-document-scan>
		</div>
		<div class="content-item__customer-field" v-show="type === 'CUSTOMER_FIELD'">
			<customer-field-type v-if="customerFieldType"
				:read-only="isEnrollmentComplete"
				:customer-field-type="customerFieldType">
				<template v-slot:label>
					<div class="content-item__label">{{ contentItem.label }}</div>
				</template>
			</customer-field-type>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.content-item {
	width: 100%;
	margin: 0.5rem 0;
	&__customer-field {
		display: flex;
		flex-direction: column;
	}
	&__document-groups {
		margin-top: 1rem;
	}
	&__document-scan {
		margin-top: 0.5rem;
	}
	&__label {
		font-size: var(--font-size-body-1);
		font-weight: var(--font-weight-body-1);
		letter-spacing: var(--letter-spacing-regular);
		padding-right: 0.3rem;
		&--document {
			font-size: 1.05rem;
			font-weight: var(--font-weight-body-2);
			letter-spacing: var(--letter-spacing-regular);
			margin: 0.5rem 0;
		}
	}
}
</style>
