<script>
import ContentGroupService from '@/services/ContentGroupService'

export default {
	name: 'ContentGroup',
	components: {
		ContentItem: () => import('./ContentItem')
	},
	props: ['contentGroupId'],
	inject: ['injectedServices'],
	data: () => ({
		contentGroupService: null
	}),
	reactiveProvide: {
		name: 'injectedGroupServices',
		include: ['contentGroupService']
	},
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		contentGroupItems: function () {
			return this.contentGroupService.getContentGroupItems()
		},
		showGroup: function () {
			return this.contentGroupService.isVisible()
		}
	},
	created () {
		this.contentGroupService = new ContentGroupService(this.enrollmentService, this.contentGroupId)
	}
}
</script>

<template>
	<div class="content-group" v-show="showGroup">
		<div class="content-group__items" v-for="contentGroupItem in contentGroupItems" :key="contentGroupItem.$id">
			<content-item :content-item="contentGroupItem.contentItem"/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.content-group {
	margin: 1rem 0;
}
</style>
