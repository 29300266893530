<script>
import CustomerFieldDate from '@/components/enrollment/fields/customer/auto-save/CustomerFieldDate'

export default {
	name: 'CustomerFieldBirthday',
	extends: CustomerFieldDate,
	props: {
		legalAge: {
			type: Number,
			default: 18
		}
	},
	computed: {
		valid: function () {
			if (!this.dirty && !this.required) return true
			let recentValue = this.date || this.value
			const momentValue = this.$moment(recentValue)
			if (!this._isMomentDateValid(momentValue)) {
				return false
			} else {
				return (this.legalAge ? this._isMomentDateLegalAge(momentValue, this.legalAge) : true)
			}
		}
	},
	methods: {
		_initializeYears: function () {
			const maxYear = this.$moment().year() - this.legalAge
			const currentYear = this.legalAge ? maxYear : this.$moment().year()
			return this._range(this.minYear, currentYear).reverse()
		},
		_isMomentDateLegalAge: function (momentDate, legalAge) {
			const age = this.$moment.duration(this.$moment().diff(momentDate)).asYears()
			if (age >= legalAge) {
				return true
			} else {
				this.patternError = this.$t('commonMsg.invalidDateAge')
				return false
			}
		}
	}
}
</script>
