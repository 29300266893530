<script>
import ContentItem from './ContentItem'
import ContentItemStore from '@/models/ContentItem'
export default {
	name: 'CardContent',
	components: { ContentItem },
	props: [ 'cardContent' ],
	computed: {
		contentItem: function () {
			return ContentItemStore.query().whereId(this.cardContent.contentItemId).withAllRecursive().first()
		}
	}
}
</script>
<template>
	<div class="card-content">
		<content-item :content-item="contentItem" />
	</div>
</template>
<style lang="scss" scoped>
	.card-content {
		width: 100%;
		margin: 0.5rem 0;
	}
</style>
