<script>
import { isEmpty } from '@/helpers/validate'
import CustomerFieldAutoSave from '@/components/enrollment/fields/customer/CustomerFieldAutoSave'
import debounce from 'lodash/debounce'

export default {
	name: 'CustomerFieldOption',
	extends: CustomerFieldAutoSave,
	data: () => ({
		options: [],
		valueKey: null,
		debouncedSubmit: null
	}),
	computed: {
		valid: function () {
			if (this.isHidden) return true
			if (isEmpty(this.value)) return !this.required
			return isEmpty(this.pattern) || (this.value.match(this.pattern) !== null)
		},
		selectedOption: function () {
			return this.options.find(option => option.key === this.valueKey)
		},
		defaultOptionKey: function () {
			const option = this.options.find(option => option.defaultOption === true)
			return option ? option.key : null
		},
		defaultValueKey: function () {
			const option = this.options.find(option => {
				return (option.label && option.label === this.value) ||
					(option.value && option.value === this.value)
			})
			return (option) ? option.key : null
		},
		enrollmentCreated: function () {
			return this.enrollmentService.enrollmentCreated()
		},
		isCachedDefault: function () {
			// Identify valid toggle fields with default values that need to be cached for group reactivity
			return !!(this.isHidden === undefined &&
				!this.isEnrollmentCreated &&
				this.isToggleField &&
				this.defaultOptionKey &&
				this.defaultOptionKey === this.valueKey)
		}
	},
	watch: {
		valueKey: async function (newValueKey) {
			// Cache default values for group reactivity before enrollment creation
			if (newValueKey && this.isCachedDefault && this.selectedOption) {
				let cachedValue = this.selectedOption.value || this.selectedOption.label
				await this.customerFieldService.setValue(cachedValue)
				await this.customerFieldService.setValid(this.valid)
			}
		},
		isEnrollmentCreated: function (newVal, oldVal) {
			// auto-update default value on enrollment creation, if it has not been edited by the user
			if (this.isHidden) return
			if (newVal && !oldVal && this.valid) {
				this.updated()
			}
		},
		isHidden: function (newVal) {
			if (this.isEnrollmentCreated && !newVal) {
				this.updated()
			} else {
				this.valueKey = this.defaultOptionKey
			}
		}
	},
	methods: {
		extractAttributes: function () {
			this.pattern = this.customerFieldType.validPattern
			if ('customerFieldOptions' in this.customerFieldType) {
				this.options = this.customerFieldType.customerFieldOptions.map((option) => ({
					key: `${option.id}`,
					label: option.label,
					value: option.value,
					defaultOption: option.defaultOption
				}))
				this._setDefaultValueKey()
			}
		},
		updated: function () {
			if (this.selectedOption) {
				this.value = this.selectedOption.value || this.selectedOption.label
			}
			if (this.valid) {
				this.debouncedSubmit()
			}
		},
		_setDefaultValueKey: function () {
			// Get value from store first, or default value if either exist
			this.valueKey = this.defaultValueKey || this.defaultOptionKey
			if (this.selectedOption) {
				this.value = this.selectedOption.value || this.selectedOption.label
			}
		}
	},
	mounted: function () {
		this.debouncedSubmit = debounce(() => {
			this.storeField()
		}, 500)
	}
}
</script>
