<script>
import BrandingService from '@/services/BrandingService'
import { isEmpty } from '@/helpers/validate'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import Noty from 'noty'
import DocumentService from '@/services/DocumentService'

export default {
	name: 'DocumentField',
	props: {
		documentField: {
			type: Object
		},
		readOnly: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		documentService: {
			type: DocumentService
		}
	},
	inject: ['injectedServices'],
	data: (vm) => ({
		brandingUi: {},
		attributeName: '',
		loading: false,
		pattern: '',
		patternError: vm.$t('commonMsg.missingIncompleteField'),
		value: vm.documentField.value
	}),
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		edited: function () {
			if (this.documentField.value) {
				return this.documentField.value !== this.value
			} else {
				return !isEmpty(this.value)
			}
		},
		showRequiredLabel: function () {
			return this.brandingUi.showRequiredLabel && this.required
		},
		showOptionalLabel: function () {
			return this.brandingUi.showOptionalLabel && !this.required
		},
		serviceTypeCode: function () {
			return this.documentService.getServiceType().code.toLowerCase()
		},
		documentFieldType: function () {
			return this.documentField.documentFieldType
		}
	},
	watch: {
		valid: {
			handler: async function (value) {
				await this.documentService.updateDocumentFieldValid(this.documentField, value)
			},
			immediate: true
		}
	},
	mounted: function () {
		this.setErrorMessages()
	},
	methods: {
		initialize: function () {
			this.brandingUi = BrandingService.get().getUi()
			this.extractDefaultAttributes()
		},
		extractDefaultAttributes: function () {
			this.value = this.documentField.value
			this.attributeName = camelCase(startCase(this.documentFieldType.name))
			if (this.hasOwnProperty('extractAttributes')) this.extractAttributes()
		},
		setErrorMessages: function () {
			let customErrorMessage = this.$t(`documentField.${this.serviceTypeCode}.${this.documentFieldType.name}.error`)
			let customGenericMessage = this.$t(`commonMsgCMS.missingIncompleteField`)
			if (customErrorMessage && !(customErrorMessage === '' && /documentField/.test(customErrorMessage.toString()))) {
				this.patternError = customErrorMessage
			} else if (customGenericMessage && !(customGenericMessage === '' || /commonMsg/.test(customGenericMessage.toString()))) {
				// Check generic custom message has been configured otherwise use default
				this.patternError = customGenericMessage
			}
		},
		cancel: function () {
			if (this.documentField.value) this.value = this.documentField.value
		},
		storeField: async function () {
			this.loading = true
			await this.documentService.updateDocumentFieldValue(this.documentField, this.value)
			await this.documentService.saveDocumentField(this.documentField)
			this.loading = false
		},
		showMessage: function (message) {
			Noty.closeAll()
			new Noty({
				text: message,
				layout: 'bottomCenter',
				timeout: 2000
			}).show()
		},
		isInvalid: function () {
			return !this.valid
		}
	}
}
</script>
