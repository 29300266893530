<script>
import FacilityService from '@/services/FacilityService'
import BrandingService from '@/services/BrandingService'
import { isEmpty } from '@/helpers/validate'
export default {
	name: 'Facility',
	props: ['loading'],
	inject: ['injectedServices'],
	data: () => ({
		brandingUi: {}
	}),
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		facility: function () {
			return this.enrollmentService ? FacilityService.getFacility(this.enrollmentService) : null
		},
		facilityFound: function () {
			return this.facility ? this.facility.found : false
		},
		facilityApiAddress: function () {
			return this.facility ? this.facility.apiAddress : ''
		},
		facilityApiPostalCode: function () {
			return this.facility ? this.facility.apiPostalCode : ''
		},
		facilityApiBuilding: function () {
			return this.facility ? this.facility.apiBuilding : ''
		},
		facilityIsExistingCustomer: function () {
			return this.facility ? this.facility.isExistingCustomer : false
		},
		existingCustomerDetection: function () {
			return this.brandingUi.existingCustomerDetection
		},
		warnExistingCustomer: function () {
			return (this.existingCustomerDetection && this.facilityIsExistingCustomer)
		},
		isCustomErrorMsg: function () {
			return (!isEmpty(this.$t('commonMsgCMS.existingCustomerError')))
		},
		existingCustomerMsg: function () {
			return this.isCustomErrorMsg
				? 'commonMsgCMS.existingCustomerError'
				: 'commonMsg.existingCustomerDefaultError'
		},
		errorMessage: function () {
			const errorLabel = this.warnExistingCustomer ? this.existingCustomerMsg : 'enrollment.locationDataNotFound'
			return this.$t(errorLabel)
		},
		showCustomError: function () {
			return (this.warnExistingCustomer && this.isCustomErrorMsg)
		},
		showDefaultError: function () {
			return (this.warnExistingCustomer && !this.isCustomErrorMsg)
		}
	},
	created: function () {
		this.brandingUi = BrandingService.get().getUi()
	}
}
</script>

<template>
	<div class="facility">
		<base-loading :loading="loading" modifiers="light-opacity" :class="{ 'facility__loading' : loading }">
			<div class="facility__container" v-show="!loading" :class="[{ 'facility__container--error' : showDefaultError },
                 { 'facility__container--regular' : !showCustomError }]">
				<div class="facility__data" v-if="facilityFound && !warnExistingCustomer">
					<div class="facility__field">
						<div class="facility__label">{{ $t('enrollment.postalCode') }}</div>
						<div class="facility__value">{{ facilityApiPostalCode }}</div>
					</div>
					<div class="facility__field">
						<div class="facility__label">{{ $t('enrollment.address') }}</div>
						<div class="facility__value">{{ facilityApiAddress }}</div>
					</div>
					<div class="facility__field">
						<div class="facility__label">{{ $t('enrollment.building') }}</div>
						<div class="facility__value">{{ facilityApiBuilding }}</div>
					</div>
				</div>
				<div v-else class="facility__error" data-testid="facility-error-message">
					<div v-if="showCustomError" class="facility__message facility__message--custom">
						<div v-html="errorMessage" />
					</div>
					<div v-else :class="showDefaultError ? 'facility__message facility__message--default' : 'facility__message'">
						<font-awesome-icon :icon="['fas', 'exclamation-circle']" class="facility__icon" />
						{{ errorMessage }}
					</div>
				</div>
			</div>
		</base-loading>
	</div>
</template>

<style lang="scss" scoped>
.facility {
	background-color: #ffffff;
	border-radius: var(--border-radius);
	color: var(--on-background);
	&__container {
		padding: 1rem;
		border-radius: var(--border-radius);
		&--regular {
			background-color: #f9f9f9;
		}
		&--error {
			background-color: #fff2f2;
			border: 1px solid #fc7979;
		}
	}
	&__loading {
		font-size: 2rem;
		min-height: 50px;
	}
	&__field {
		font-size: var(--font-size-body-1);
		font-weight: var(--font-weight-body-1);
		margin: 1rem 0;
	}
	&__label {
		font-size: var(--font-size-body-2);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
		margin-right: 0.5rem;
	}
	&__error {
		display: flex;
		align-content: center;
	}
	&__icon {
		margin-right: 0.5rem;
		margin-top: 0.26rem;
	}
	&__message {
		font-size: var(--font-size-body-1);
		font-weight: var(--font-weight-body-1);
		display: flex;
		&--default {
			color: #ff0000;
			font-weight: var(--font-weight-subheading);
		}
		&--custom {
			color: var(--on-background);
			background-color: var(--on-accent);
			padding: 0;
			border: none;
		}
	}
}
</style>
