<script>
export default {
	name: 'ErrorMessages',
	props: ['errorService'],
	computed: {
		messages: function () {
			return this.errorService.errors.map(currentError => currentError.message)
		}
	}
}
</script>
<template>
	<div class="error-messages">
		<div class="error-messages__card" v-for="message in messages" :key="message">
			<font-awesome-icon :icon="['fas', 'exclamation-circle']" class="error-messages__icon"/>
			<div class="error-messages__text">{{ message }}</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.error-messages {
	&__card {
		background-color: var(--surface-colour);
		border: 1px solid #eee;
		border-radius: 5px;
		color: var(--on-surface);
		display: flex;
		padding: 1rem;
		width: 100%;
		align-items: center;
		margin: 0.5rem 0;
	}
	&__icon {
		margin-right: 1rem;
	}
}
</style>
