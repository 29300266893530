<script>
import CustomerFieldAutoSave from '@/components/enrollment/fields/customer/CustomerFieldAutoSave'
import CustomerFieldTextSimple from '@/components/enrollment/fields/customer/simple/CustomerFieldTextSimple'
import { isEmpty } from '@/helpers/validate.js'

export default {
	name: 'CustomerFieldDuplicated',
	components: { CustomerFieldTextSimple },
	extends: CustomerFieldAutoSave,
	props: [ 'duplicatedFieldName' ],
	data: () => ({
		inputs: [
			{
				value: '',
				valid: true,
				edited: false
			},
			{
				value: '',
				valid: true,
				edited: false
			}
		]
	}),
	computed: {
		edited: function () {
			return this.checkEdits()
		},
		valid: function () {
			return this.checkValues() && this.checkValidity()
		},
		isInvalid: function () {
			return !this.valid && this.edited
		},
		enableDuplicateFieldValidation: function () {
			return this.inputs[0].valid || !isEmpty(this.inputs[1].value)
		},
		isPhoneField: function () {
			return /000-0000-0000|000-000-0000|00-0000-0000|0000-00-0000/.test(this.customerFieldType.mask) ||
				/mobile|phone/.test(this.customerFieldType.code)
		},
		duplicateErrorMessage: function () {
			return this.isPhoneField
				? this.$t('commonMsg.duplicatePhoneError')
				: this.$t('commonMsg.duplicateFieldError')
		},
		showDuplicateErrorMessage: function () {
			return !this.valid && this.isInvalid && this.enableDuplicateFieldValidation
		}
	},
	watch: {
		valid: async function (newValue) {
			if (newValue) {
				if (!this.edited) return
				this.value = this.inputs[0].value !== '' ? this.inputs[0].value : this.defaultValue
				await this.storeField()
			}
		}
	},
	methods: {
		editChanged: function (index, value) {
			this.inputs[index].edited = value
		},
		valueChanged: function (index, value) {
			this.inputs[index].value = value
		},
		validityChanged: function (index, value) {
			this.inputs[index].valid = value
		},
		checkEdits: function () {
			return this.inputs[0].edited || this.inputs[1].edited
		},
		checkValues: function () {
			return this.inputs[0].value === this.inputs[1].value ||
				(isEmpty(this.inputs[0].value) && isEmpty(this.inputs[1].value))
		},
		checkValidity: function () {
			if (!this.required && isEmpty(this.inputs[0].value) && isEmpty(this.inputs[1].value)) {
				return true
			}
			return this.inputs[0].valid && this.inputs[1].valid
		}
	}
}
</script>
<template>
	<div class="customer-field-duplicated">
		<div class="customer-field-duplicated__input" v-for="(_input, index) in inputs" :key="index">
			<div class="customer-field-duplicated__label">
				<base-label v-if="showRequiredLabel"
							modifiers="required height-25 margin-r"
							:value="$t('enrollment.required')" />
				<base-label v-else-if="showOptionalLabel"
							modifiers="optional height-25 margin-r"
							:value="$t('enrollment.optional')" />
				<div class="customer-field-duplicated__label--original">
					<slot name="label"></slot>
					<span class="customer-field-duplicated__label--repeat" v-if="index === 1">
                  {{$t('enrollment.repeat')}}
               </span>
				</div>
			</div>
			<component
				:is="`${duplicatedFieldName}Simple`"
				v-bind="$attrs"
				v-on="$listeners"
				:customer-field-type="customerFieldType"
				:invalid="isInvalid"
				:is-duplicate-field="index > 0"
				:enable-duplicate-field-validation="enableDuplicateFieldValidation"
				@valueChanged="valueChanged(index, ...arguments)"
				@validityChanged="validityChanged(index, ...arguments)"
				@editChanged="editChanged(index, ...arguments)"></component>
		</div>
		<div class="customer-field-duplicated__error" v-show="showDuplicateErrorMessage">{{ duplicateErrorMessage }}</div>
	</div>
</template>
<style lang="scss" scoped>
.customer-field-duplicated {
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0;
		&--original {
			& > * {
				display: inline;
				margin: 0 0;
			}
		}
		&--repeat {
			padding-right: 0.3rem;
			white-space: nowrap;
		}
	}
	&__input {
		margin: 0.5rem 0;
	}
	&__error {
		color: red;
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-top: 0.25rem;
		text-align: left;
	}
}
</style>
