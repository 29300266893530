import FacilityService from '@/services/FacilityService'
import BrandingService from '@/services/BrandingService'
import rg4js from 'raygun4js'
export default {
	data: () => ({
		brandingConfig: {},
		facility: null
	}),
	computed: {
		existingCustomerDetection: function () {
			return this.brandingUi.existingCustomerDetection
		},
		facilityIsExistingCustomer: function () {
			return this.facility ? this.facility.isExistingCustomer : false
		}
	},
	watch: {
		facilityIsExistingCustomer: async function () {
			await this._updateValid()
		},
		facility: async function (value) {
			if (value !== null) await this._updateValid()
		},
		immediate: true
	},
	created: function () {
		this.brandingUi = BrandingService.get().getUi()
	},
	mounted: async function () {
		await this._updateValid()
		await this.storeOCCTOField()
	},
	methods: {
		save: async function () {
			if (!this.edited) return
			await this.storeOCCTOField()
		},
		storeOCCTOField: async function () {
			if (this.valid && this.value) {
				await this.storeField()
				const locationId = this.value
				try {
					this.facility = null
					this.facility = await FacilityService.loadFacility(this.enrollmentService, locationId)
					this.$emit('loaded', true)
				} catch (error) {
					console.error(error)
					await rg4js('send', {
						error: `OCCTO Facility error. ${error}`,
						customData: [{ locationId }],
						tags: [`${this.$options.name}`]
					})
				}
			} else {
				this.$emit('loaded', false)
			}
		}
	}
}
