<script>
import BaseLabel from 'lowfoot-components/BaseLabel'
import BaseModal from 'lowfoot-components/BaseModal'
import DocumentIllustration from '@/components/enrollment/documents/DocumentIllustration'
import ProcessingDocument from '@/components/enrollment/documents/ProcessingDocument'
import BrandingService from '@/services/BrandingService'
import DocumentService from '@/services/DocumentService'

export default {
	name: 'ContentDocumentScan',
	props: [ 'contentDocumentScan', 'readOnly' ],
	inject: ['injectedServices', 'injectedGroupServices'],
	components: { BaseLabel, BaseModal, DocumentIllustration, ProcessingDocument },
	data: (vm) => ({
		brandingUi: {},
		injectedServices: vm.injectedServices,
		documentService: null
	}),
	reactiveProvide: {
		name: 'injectedDocumentService',
		include: ['documentService']
	},
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		contentGroupService: function () {
			return this.injectedGroupServices ? this.injectedGroupServices.contentGroupService : null
		},
		isDocumentScanned: function () {
			return this.processingDocument && this.processingDocument.status === 'COMPLETE'
		},
		processingDocument: function () {
			return this.documentService ? this.documentService.getProcessingDocument() : null
		},
		isRequired: function () {
			return !this.contentDocumentScan.optional
		},
		modalIllustrationId: function () {
			return `modalIllustration-${this.contentDocumentScan.id}`
		},
		showRequiredLabel: function () {
			return this.brandingUi.showRequiredLabel && this.isRequired
		},
		showOptionalLabel: function () {
			return this.brandingUi.showOptionalLabel && !this.isRequired
		},
		serviceTypeId: function () {
			return this.contentDocumentScan.serviceTypeId
		},
		regionId: function () {
			return this.enrollmentService ? this.enrollmentService.getRegionId() : null
		},
		serviceProviderId: function () {
			return this.documentService ? this.documentService.getServiceProviderId() : null
		},
		isHidden: function () {
			return this.contentGroupService && !this.contentGroupService.isVisible()
		}
	},
	created: function () {
		this.brandingUi = BrandingService.get().getUi()
		this.documentService = new DocumentService(this.enrollmentService, this.contentDocumentScan, this.contentGroupService)
	},
	methods: {
		takePhoto: function () {
			this.$router.push({ name: 'camera', params: { 'contentDocumentScan': this.contentDocumentScan } })
		}
	},
	watch: {
		isHidden: async function (newVal, oldVal) {
			if (newVal && !oldVal && this.documentService && this.documentService.getProcessingDocument()) {
				await this.documentService.deleteProcessingDocument()
			}
		}
	}
}
</script>
<template>
	<div class="content-document-scan" :id="'content-document-scan-' + contentDocumentScan.id">
		<div class="content-document-scan__label">
			<base-label v-show="showRequiredLabel"
						modifiers="required height-25 margin-r"
						:value="$t('enrollment.required')" />
			<base-label v-show="showOptionalLabel"
						modifiers="optional height-25 margin-r"
						:value="$t('enrollment.optional')" />
			<slot name="label"></slot>
		</div>
		<document-illustration
			v-if="!isDocumentScanned"
			class="content-document-scan__illustrations"
			:service-type-id="serviceTypeId"
			:region-id="regionId"
			:service-provider-id="serviceProviderId"
			:key="serviceTypeId"/>
		<div v-else @click="$modal.show(modalIllustrationId)"
			class="content-document-scan__link">
			{{ $t('illustration.example') }}
		</div>
		<div v-if="isDocumentScanned" class="content-document-scan__scanned" :key="serviceTypeId">
			<processing-document
				:required="!contentDocumentScan.optional"
				:processing-document="processingDocument"
				:read-only="readOnly" />
			<base-button class="content-document-scan__button"
						modifiers="accent-inverse font-small small"
						:disabled="readOnly"
						@click="takePhoto">
				{{ $t('enrollment.document.rescan') }}
			</base-button>
		</div>
		<div v-else class="content-document-scan__empty">
			<base-button class="content-document-scan__button"
						modifiers="accent font-small small"
						:disabled="readOnly"
						@click="takePhoto">
				{{ $t('enrollment.document.scan') }}
			</base-button>
		</div>
		<base-modal modifiers="padding-1rem" :modal-name="modalIllustrationId"
					:height="'auto'" :adaptive="true" :width="'95%'"
					:max-width="500" :scrollable="true" :clickToClose="false">
			<div class="content-document-scan__modal">
				<base-button slot="top-right" modifiers="accent circle xxxs-circle" class="content-document-scan__icon"
					@click.native="$modal.hide(modalIllustrationId)">
					<font-awesome-icon :icon="['fas', 'times']" />
				</base-button>
				<document-illustration
					:service-type-id="serviceTypeId"
					:region-id="regionId"
					:service-provider-id="serviceProviderId"/>
			</div>
		</base-modal>
	</div>
</template>
<style lang="scss" scoped>
.content-document-scan {
	width: 100%;
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0 0 0;
	}
	&__link {
		color: var(--link-colour);
		cursor: pointer;
		font-size: 1rem;
		filter: brightness(80%);
		margin-top: 1rem;
		text-align: center;
		text-decoration: underline;
	}
	&__scanned {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__empty {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
</style>
