<script>
import DocumentIllustrationService from '@/services/DocumentIllustrationService'

export default {
	name: 'DocumentIllustration',
	props: ['serviceTypeId', 'regionId', 'serviceProviderId'],
	computed: {
		illustrations: function () {
			return DocumentIllustrationService.get(this.serviceTypeId, this.regionId, this.serviceProviderId)
		},
		showIllustrations: function () {
			return this.illustrations && this.illustrations.length > 0
		},
		multipleIllustrations: function () {
			return this.illustrations && this.illustrations.length > 1
		}
	}
}
</script>
<template>
	<div class="document-illustration">
		<div class="document-illustration__illustration-group" v-if="showIllustrations">
			<div v-for="(illustration, index) in illustrations" :key="illustration.id">
				<img alt="illustration" class="document-illustration__image" :src="illustration.url" />
				<div v-if="multipleIllustrations && (index < illustrations.length - 1)"
					class="document-illustration__text">
					{{ $t("public.or") }}
				</div>
			</div>
			<div class="document-illustration__note">{{ $t('illustration.note')}}</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.document-illustration {
	max-width: 500px;
	margin: 0 auto;
	&__illustration-group {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0.5rem 0 1rem 0;
		text-align: center;
	}
	&__image {
		margin: 0.5rem 0;
		width: 100%;
		max-width: 500px;
	}
	&__text {
		font-size: 1rem;
	}
	&__note {
		font-size: 0.8rem;
		align-self: flex-end;
	}
}
</style>
