<script>
import CustomerFieldText from '@/components/enrollment/fields/customer/auto-save/CustomerFieldText'
import BaseInput from 'lowfoot-components/BaseInput'
import { isEmpty } from '@/helpers/validate.js'

export default {
	name: 'CustomerFieldPhone',
	extends: CustomerFieldText,
	components: { BaseInput },
	data: () => ({
		value1: '',
		value2: '',
		value3: '',
		phonePatterns: '^[0-9]{3}-[0-9]{4}-[0-9]{4}$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$|^[0-9]{4}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{4}-[0-9]{4}$|^[0-9]{5}-[0-9]{1}-[0-9]{4}$'
	}),
	computed: {
		valid: function () {
			if (isEmpty(this.value) || isEmpty(this.value.replace(/-/g, ''))) return !this.required
			return isEmpty(this.pattern) ? (this.value.match(this.phonePatterns) !== null) : (this.value.match(this.pattern) !== null)
		},
		formattedValue: function () {
			if (!isEmpty(this.value1) || !isEmpty(this.value2) || !isEmpty(this.value3)) {
				return this.value1.concat('-', this.value2, '-', this.value3)
			} else {
				return ''
			}
		}
	},
	watch: {
		value: function (newValue) {
			if (newValue == null || this.isHidden) {
				this.value1 = this.value2 = this.value3 = ''
			} else {
				this._loadValue(newValue)
			}
		},
		formattedValue: function (newValue) {
			if (!this.isHidden) {
				this.value = newValue
			} else {
				this.value = null
			}
		}
	},
	mounted: async function () {
		this._loadValue(this.storedValue)
	},
	methods: {
		_loadValue: function (value) {
			if (!value || isEmpty(value)) return
			const phone = this.value.split('-')
			this.value1 = phone[0]
			this.value2 = phone[1]
			this.value3 = phone[2]
			this.value = value
		}
	}
}
</script>

<template>
	<form class="customer-field-phone" @submit.capture.prevent="" v-if="customerFieldType">
		<div class="customer-field-phone__label" >
			<base-label v-if="showRequiredLabel"
						modifiers="required height-25 margin-r"
						:value="$t('enrollment.required')" />
			<base-label v-else-if="showOptionalLabel"
						modifiers="optional height-25 margin-r"
						:value="$t('enrollment.optional')" />
			<slot name="label"></slot>
		</div>
		<div class="customer-field-phone__input-options">
			<div class="customer-field-phone__input-group">
				<base-input class="customer-field-phone__input"
						v-model.trim="value1"
						:disabled="readOnly"
						:inputmode="'tel'"
						:invalid="isInvalid"
						:mask="'00000'"
						:name="attributeName"
						:required="required"
						@blur="handleBlur($event)"
						@keyup="handleKeyUp($event)"
						@input="handleInput($event)"
						@change="handleInput($event)"
						@invalid.capture.prevent=""/>
				<div class="customer-field-phone__separator">-</div>
				<base-input class="customer-field-phone__input"
						v-model.trim="value2"
						:disabled="readOnly"
						:inputmode="'tel'"
						:invalid="isInvalid"
						:mask="'0000'"
						:name="attributeName"
						:required="required"
						@blur="handleBlur($event)"
						@keyup="handleKeyUp($event)"
						@input="handleInput($event)"
						@change="handleInput($event)"
						@invalid.capture.prevent=""/>
				<div class="customer-field-phone__separator">-</div>
				<base-input class="customer-field-phone__input"
						v-model.trim="value3"
						:disabled="readOnly"
						:inputmode="'tel'"
						:invalid="isInvalid"
						:mask="'0000'"
						:name="attributeName"
						:required="required"
						@blur="handleBlur($event)"
						@keyup="handleKeyUp($event)"
						@input="handleInput($event)"
						@change="handleInput($event)"
						@invalid.capture.prevent=""/>
				<div class="customer-field-phone__spinner" v-show="valid && edited">
					<font-awesome-icon :icon="['fas', 'spinner']" class="fa-spin" spin />
				</div>
			</div>
			<div class="customer-field-phone__icon"  @click="cancel()" v-show="brandingUi.showResetButton && !valid && edited">
				<font-awesome-icon :icon="['fas', 'undo']"/>
			</div>
		</div>
		<div class="customer-field-phone__error" v-show="isInvalid">{{ patternError }}</div>
	</form>
</template>

<style lang="scss" scoped>
.customer-field-phone {
	flex-grow: 1;
	flex-direction: column;
	width: 100%;
	position: relative;
	&__required-label {
		display: flex;
	}
	&__label {
		display: flex;
		align-items: center;
		margin: 0.5rem 0;
	}
	&__separator {
		align-self: center;
		font-weight: bold;
		margin: 0 0.50rem;
	}
	&__input {
		font-size: 1.1rem;
		text-overflow: ellipsis;
		height: 2.5rem;
		width: 4.45rem;
		&-options {
			display: flex;
			align-items: center;
			width: 100%;
		}
		&-group {
			display: flex;
			align-items: center;
			position: relative;
		}
	}
	&__icon {
		padding-left: 0.5rem;
	}
	&__error {
		color: red;
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-top: 0.25rem;
	}
	&__spinner {
		position: absolute;
		z-index: 10;
		right: -10%;
		opacity: 0.9;
		transition: .5s ease;
	}
}
</style>
