import { InstanceOf } from '@vuex-orm/core'
import DocumentIllustration from '@/models/DocumentIllustration'

export default class DocumentIllustrationService {
	documentIllustration: InstanceOf<DocumentIllustration>

	constructor (documentIllustration: InstanceOf<DocumentIllustration>) {
		this.documentIllustration = documentIllustration
	}

	static get (serviceTypeId, regionId, serviceProviderId = null) {
		return DocumentIllustration.query()
			.where('serviceTypeId', serviceTypeId)
			.where('regionId', regionId)
			.where('serviceProviderId', serviceProviderId)
			.get()
	}
}
