<script>
import BaseSelect from 'lowfoot-components/BaseSelect'
import CustomerFieldOption from '@/components/enrollment/fields/customer/auto-save/CustomerFieldOption'

export default {
	name: 'CustomerFieldSelect',
	extends: CustomerFieldOption,
	components: { BaseSelect }
}
</script>
<template>
	<form class="customer-field-select" @submit.capture.prevent="" v-if="customerFieldType" v-show="!isHidden">
		<div class="customer-field-select__label">
			<base-label v-if="showRequiredLabel"
						modifiers="required height-25 margin-r"
						:value="$t('enrollment.required')" />
			<base-label v-else-if="showOptionalLabel"
						modifiers="optional height-25 margin-r"
						:value="$t('enrollment.optional')" />
			<slot name="label"></slot>
		</div>
		<div class="customer-field-select__wrapper">
			<div class="customer-field-select__group">
				<base-select
					:read-only="readOnly"
					:options="options"
					v-model="valueKey"
					:invalid="edited && !valid"
					@input="updated"
					:placeholder-value="$t('customerField.option.placeholder')"/>
				<div class="customer-field-select__spinner" v-show="loading">
					<font-awesome-icon :icon="['fas', 'spinner']" class="fa-spin" spin />
				</div>
			</div>
			<div class="customer-field-select__icon"  @click="cancel()" v-show="brandingUi.showResetButton && !valid && edited">
				<font-awesome-icon :icon="['fas', 'undo']"/>
			</div>
			<div class="customer-field-select__error" v-show="!valid && edited">{{ patternError }}</div>
		</div>
	</form>
</template>
<style lang="scss">
.customer-field-select{
	&__label {
		align-items: center;
		display: flex;
		margin: 0.5rem 0;
	}
	&__group {
		position: relative;
	}
	&__spinner {
		position: absolute;
		z-index: 10;
		right: 7%;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0.9;
		transition: .5s ease;
	}
	&__error {
		color: red;
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-top: 0.25rem;
	}
}
</style>
