<script>
import CardContent from '@/components/enrollment/cards/CardContent'
import BrandingService from '@/services/BrandingService'
import CardService from '@/services/CardService'
import CustomerFieldService from '@/services/CustomerFieldService'
import DocumentService from '@/services/DocumentService'

export default {
	name: 'Card',
	props: [ 'card' ],
	inject: ['injectedServices'],
	components: { CardContent },
	data: () => ({
		brandingUi: {},
		visibility: true,
		cardService: null
	}),
	reactiveProvide: {
		name: 'injectedCardServices',
		include: ['cardService']
	},
	computed: {
		enrollmentService: function () {
			return this.injectedServices.enrollmentService
		},
		cardContents: function () {
			return this.cardService ? this.cardService.getCardContents() : null
		},
		containsDocumentScan: function () {
			return this.cardContents && this.cardContents
				.filter((cardContent) => cardContent.contentItem && cardContent.contentItem.contentType === 'DOCUMENT_SCAN')
				.length > 0
		},
		processingDocumentsComplete: function () {
			return this.enrollmentService && DocumentService.validateAllByCardId(this.enrollmentService, this.card.id)
		},
		showContinue: function () {
			if (!this.containsDocumentScan) return true
			return this.processingDocumentsComplete
		},
		enableSectionToggle: function () {
			return this.brandingUi && this.brandingUi.enableSectionToggle
		}
	},
	created: function () {
		this.brandingUi = BrandingService.get().getUi()
	},
	mounted: async function () {
		this.cardService = new CardService(this.enrollmentService, this.card.id)
		if (CustomerFieldService.getCustomerFieldsByCard(this.card.id).length === 0) await this.cardService.validate(this.enrollmentService)
	},
	activated: function () {
		if (this.$store.getters['cms/getConfirmationCheckpoint']) this.visibility = true
	},
	methods: {
		collapse: function () {
			this.visibility = false
			this.scrollToCard(this.$refs.cardElement.nextSibling)
		},
		expand: function () {
			if (!this.enableSectionToggle) return
			this.visibility = true
			this.scrollToCard(this.$refs.cardElement)
		},
		scrollToCard: function (scrollCard) {
			if (!scrollCard) return false
			requestAnimationFrame(() => scrollCard.scrollIntoView({ behavior: 'smooth' }))
		}
	}
}
</script>
<template>
	<div class="enrollment-card" ref="cardElement">
		<div class="enrollment-card__header" @click="expand">
			<div class="enrollment-card__number">{{ card.order }}</div>
			<div class="enrollment-card__title">{{ card.name }}</div>
			<font-awesome-icon :icon="['fa', 'chevron-down']" class="enrollment-card__icon" v-show="!visibility"/>
		</div>
		<div v-show="cardContents && visibility" class="enrollment-card__contents">
			<card-content :card-content="cardContent" v-for="cardContent in cardContents" :key="cardContent.id"/>
			<base-button v-show="enableSectionToggle && showContinue"
				class="enrollment-card__button"
				modifiers="accent large full margin-y"
				:disabled="!card.$valid"
				@click="collapse">
				{{ $t('enrollment.continue') }}
			</base-button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.enrollment-card {
	background-color: var(--surface-colour);
	color: var(--on-surface);
	width: 100%;
	padding: 1rem;
	margin: 0.5rem 0;
	border: 1px solid var(--background-variant-light);
	border-radius: var(--border-radius);
	@media only screen and (min-width: 550px) {
		padding: 1.5rem;
	}
	&__header {
		display: flex;
	}
	&__icon {
		margin-top: 0.7rem;
		margin-left: auto;
	}
	&__number {
		background-color: var(--accent-colour);
		color: var(--on-accent);
		height: 35px;
		min-width: 35px;
		line-height: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
	}
	&__title {
		display: flex;
		margin: 0.25rem 1rem;
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
	}
}
</style>
