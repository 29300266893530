import { InstanceOf } from '@vuex-orm/core'
import ServiceProvider from '@/models/ServiceProvider'
import Region from '@/models/Region'
import ProviderServiceRegion from '@/models/ProviderServiceRegion'

export default class ServiceProviderService {
	serviceProvider: InstanceOf<ServiceProvider>

	constructor (serviceProvider: InstanceOf<ServiceProvider>) {
		this.serviceProvider = serviceProvider
	}

	static find (serviceProviderId) {
		return ServiceProvider.query().withAll().find(serviceProviderId)
	}

	static getByRegionId (regionId: number) {
		// TODO: OrderBy in many to many associations doesn't work https://github.com/vuex-orm/vuex-orm/issues/571
		const region: InstanceOf<Region> = Region.query().withAll().find(regionId)
		if (!region) return []
		return region.serviceProviders
	}

	static getByServiceRegion (serviceTypeId: number, regionId: number) {
		return ProviderServiceRegion.query().withAll()
			.where('regionId', regionId)
			.where('serviceTypeId', serviceTypeId)
			.get()
			.map((providerServiceRegion: InstanceOf<ServiceProvider>) => providerServiceRegion.serviceProvider)
	}

	static getIncumbent (serviceTypeId, regionId) {
		const providerServiceRegions = ProviderServiceRegion.query().withAll()
			.where('isIncumbent', true)
			.where('regionId', regionId)
			.where('serviceTypeId', serviceTypeId).get()
		if (providerServiceRegions.length !== 1) return null
		const providerServiceRegion: InstanceOf<ProviderServiceRegion> = providerServiceRegions[0]
		return providerServiceRegion.serviceProvider
	}
}
